import React, { useState, useEffect } from 'react';
import LoginPage from './login/LoginPage';
import StartSessionForm from './session/StartSessionForm';

import {
  createSession,
  getEnvironments,
  getSessionTypes,
  getEnvironmentCountries,
} from './api';
import { toast } from 'react-toastify';

function ParentComponent() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState('');

  const [selectedEnvironment, setSelectedEnvironment] = useState();

  const [environments, setEnvironments] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);

  const handleRequest = async promise => {
    try {
      const resp = await promise;
      const body = await resp.json();

      if (resp.status === 200) return body;

      if (resp.status === 401) {
        setLoggedIn(false);
        setPassword('');
        setCountries([]);
        setEnvironments([]);
        setSessionTypes([]);
        setSelectedEnvironment(undefined);
      }

      toast.error(
        `${resp.status} ERROR ${'detail' in body ? body.detail : ''}`
      );
    } catch (err) {
      toast.error(`Unexpected error: ${err}`);
    }
  };

  // On start, try to load the environments and session types.
  useEffect(() => {
    if (!password) return;

    (async () => {
      const environments = await handleRequest(getEnvironments(password));
      if (!environments) return;

      const sessionTypes = await handleRequest(getSessionTypes(password));
      if (!sessionTypes) return;

      setEnvironments(environments);
      setSessionTypes(sessionTypes);
    })();
  }, [password]);

  useEffect(() => {
    if (!environments || environments?.length === 0) return;
    if (!sessionTypes || sessionTypes?.length === 0) return;

    setLoggedIn(true);
  }, [environments, sessionTypes]);

  useEffect(() => {
    if (!selectedEnvironment) return;

    (async () => {
      const response = await handleRequest(
        getEnvironmentCountries(password, selectedEnvironment)
      );
      if (!response) return;

      setCountries(response.countries);
    })();
  }, [selectedEnvironment, password]);

  const handleLoginSubmit = password => {
    if (!password || password === '') return;
    setPassword(password);
  };

  const handleProfileSubmit = formData => {
    const windowReference = window.open();

    (async () => {
      const createSessionRequest = {
        country_id: countries.find(c => c.name === formData.country).id,
        environment: formData.environment,
        session_type: sessionTypes[formData.sessionType],
      };
      if (formData.amount) createSessionRequest.amount = formData.amount;

      const response = await handleRequest(
        createSession(password, createSessionRequest)
      );

      windowReference.location = response.url;
    })();
  };

  return (
    <>
      {loggedIn ? (
        <StartSessionForm
          environmentOptions={environments}
          sessionTypeOptions={Object.keys(sessionTypes)}
          countryOptions={countries.map(c => c.name)}
          onSubmit={handleProfileSubmit}
          onSelectEnvironment={setSelectedEnvironment}
        />
      ) : (
        <LoginPage onSubmit={handleLoginSubmit} />
      )}
    </>
  );
}

export default ParentComponent;
