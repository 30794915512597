import React, { useState, useEffect } from 'react';
import './StartSessionForm.css';

function StartSessionForm({
  environmentOptions,
  sessionTypeOptions,
  countryOptions,
  onSubmit,
  onSelectEnvironment,
}) {
  const [environment, setEnvironment] = useState('');
  const [sessionType, setSessionType] = useState('');
  const [country, setCountry] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    if (sessionType === 'DEPOSIT') return;
    setAmount('');
  }, [sessionType]);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ environment, sessionType, country, amount: Number(amount) });
  };

  // Need to load countries based on environment so passing the value up to
  // the parent component which will do the fetching.
  const onChangeEnvironment = e => {
    setEnvironment(e.target.value);
    onSelectEnvironment(e.target.value);
  };

  return (
    <div className="d-flex justify-content-center align-items-center profile-container">
      <div className="border p-4 bg-white shadow rounded">
        <h1 className="mb-4">Brite Test System</h1>
        <p>
          Begin a test session by choosing your environment, session type,
          country, and amount.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <select
              data-testid="environment"
              className="form-select"
              onChange={e => onChangeEnvironment(e)}
            >
              <option value="">Select Environment</option>
              {environmentOptions?.map(
                o =>
                  (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ) ?? []
              )}
            </select>
          </div>
          <div className="mb-3">
            <select
              className="form-select"
              data-testid="sessionType"
              value={sessionType}
              onChange={e => setSessionType(e.target.value)}
            >
              <option value="">Select Session Type</option>
              {sessionTypeOptions?.map(
                o =>
                  (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ) ?? []
              )}
            </select>
          </div>
          <div className="mb-3">
            <select
              className="form-select"
              data-testid="country"
              value={country}
              onChange={e => setCountry(e.target.value)}
            >
              <option value="">Select Country</option>
              {countryOptions?.map(
                o =>
                  (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ) ?? []
              )}
            </select>
          </div>
          {sessionType === 'DEPOSIT' && (
            <div className="mb-3">
              <input
                data-testid="amount"
                type="number"
                className="form-control"
                placeholder="Amount"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            </div>
          )}
          <button
            disabled={
              !(
                environment &&
                environment !== '' &&
                sessionType &&
                sessionType !== '' &&
                country &&
                country !== '' &&
                (sessionType !== 'DEPOSIT' || (amount && amount !== ''))
              )
            }
            type="submit"
            className="btn btn-primary w-100"
          >
            Start session
          </button>
        </form>
      </div>
    </div>
  );
}

export default StartSessionForm;
