const isDevelopment = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const getEnvironments = async apiKey => {
  if (isDevelopment()) {
    return new Promise((resolve, _) =>
      resolve(
        new Response(JSON.stringify(['staging', 'production']), { status: 200 })
      )
    );
  }

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/environments`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
  });
};

export const getSessionTypes = async apiKey => {
  if (isDevelopment()) {
    return new Promise((resolve, _) =>
      resolve(
        new Response(
          JSON.stringify({
            AUTHENTICATION: 0,
            ACCOUNT_SELECTION: 1,
            DEPOSIT: 2,
          }),
          { status: 200 }
        )
      )
    );
  }

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sessions/types`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
  });
};

export const getEnvironmentCountries = async (apiKey, environment) => {
  if (isDevelopment()) {
    return new Promise((resolve, _) =>
      resolve(
        new Response(
          JSON.stringify({
            countries: [
              { id: 'SE', name: 'Sverige', currency_id: 'SEK' },
              { id: 'HR', name: 'Hrvatska', currency_id: 'EUR' },
            ],
          }),
          { status: 200 }
        )
      )
    );
  }

  return fetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/environments/${environment}/countries`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    }
  );
};

export const createSession = async (apiKey, body) => {
  if (isDevelopment()) {
    return new Promise((resolve, _) =>
      resolve(
        new Response(
          JSON.stringify({
            id: 'asdf',
            url: 'https://britepayments.com',
            session_token: 'session_token',
          }),
          { status: 200 }
        )
      )
    );
  }

  return fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sessions`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    body: JSON.stringify({ ...body }),
  });
};
