import React, { useState } from 'react';
import './LoginPage.css';

function LoginPage({
  onSubmit,
  buttonText = 'Submit',
  headerText = 'Brite Test System',
}) {
  const [password, setPassword] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(password);
  };

  return (
    <div className="d-flex justify-content-center align-items-center login-container">
      <div className="border p-4 mt-5 bg-white shadow rounded">
        <h1 className="mb-4">{headerText}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              id="passwordInput"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            disabled={!(password && password !== '')}
            className="btn btn-primary w-100"
          >
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
