import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import ParentComponent from '../ParentComponent';

export default function App() {
  return (
    <div className="App">
      <ToastContainer />
      <ParentComponent />
    </div>
  );
}
